import React, { Suspense, useEffect, useState } from "react";
import { useFetchConfig } from "./hooks/useFetchConfig";
import { IConfig } from "./interfaces/IConfig";
import { configContext } from "./providers/Config.context";
import "./_app.scss";

const LazyHome = React.lazy(() =>
  import("./screens/Home/Home").then(({ Home }) => ({ default: Home }))
);

const Loading = () => <h1>Loading...</h1>;

type IAppProps = {
  config?: IConfig;
  baseUrl?: string;
};
export const App = ({ config: _config, baseUrl }: IAppProps) => {
  const [config, setConfig] = useState(_config);
  const fetchConfig = useFetchConfig(baseUrl);
  useEffect(() => {
    if (!config) {
      fetchConfig().then(setConfig);
    } else {
      document.title = `${config.name} Home`;
    }
  }, [fetchConfig, config]);

  if (!config) return <Loading />;

  return (
    <configContext.Provider value={{ config, fetchConfig }}>
      <Suspense fallback={<Loading />}>
        <LazyHome />
      </Suspense>
    </configContext.Provider>
  );
};
