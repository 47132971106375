import { IConfig } from "../interfaces/IConfig";
import { useCallback } from "react";
import { fetchConfig } from "../api/fetchConfig";

export const useFetchConfig = (baseUrl?: string) => {
  const _fetchConfig = useCallback<() => Promise<IConfig | undefined>>(() => {
    return fetchConfig(baseUrl);
  }, [baseUrl]);
  return _fetchConfig;
};
