import { IConfig } from "../interfaces/IConfig";
import { getCredentials } from "./getCredentials";

export const fetchConfig = (
  baseUrl: string = window.location.href
): Promise<IConfig | undefined> => {
  const credentials = getCredentials();
  const configPath = process.env.REACT_APP__CONFIG_PATH ?? `config/config.json`;

  const conf_url = new URL(configPath, baseUrl).href;
  return fetch(conf_url, { credentials })
    .then((data) => data.json())
    .then((data) =>
      Object.entries(data).reduce(
        (prev, [key, value]) => ({
          ...prev,
          [key]: value === "Yes" || value === "No" ? value === "Yes" : value,
        }),
        {}
      )
    )
    .catch((error) => {
      console.error(error);
      return undefined;
    });
};
