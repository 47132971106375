import React from "react";
import { IConfig } from "../interfaces/IConfig";

export type IConfigContextState = {
  config: IConfig;
};
export type IConfigContextCallbacks = {
  fetchConfig: () => void;
};

type IConfigContext = IConfigContextState & IConfigContextCallbacks;
export const configContext = React.createContext<IConfigContext>({
  config: {},
  fetchConfig: () => {},
});
